@font-face {
  font-family: 'TF2 Build';
  src: url('../public/fonts/TF2Build.eot');
  src: url('../public/fonts/TF2Build.eot?#iefix') format('embedded-opentype'),
  url('../public/fonts/TF2Build.woff2') format('woff2'),
  url('../public/fonts/TF2Build.woff') format('woff'),
  url('../public/fonts/TF2Build.ttf') format('truetype'),
  url('../public/fonts/TF2Build.svg#TF2Build') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
