@import "fonts";
@import 'foundationSettings';
@import '~foundation-sites/scss/util/util';
@import '~foundation-sites/scss/util/mixins';
@import '~foundation-sites/scss/components/dropdown-menu';
@import '~foundation-sites/scss/components/dropdown';
@import '~foundation-sites/scss/components/menu';
@import '~foundation-sites/scss/components/button';
@import '~foundation-sites/scss/components/table';
@import '~foundation-sites/scss/xy-grid/xy-grid';
@import '~foundation-sites/scss/components/callout';
@import "~motion-ui/motion-ui";
@import "icons";

#header {
    .image {
        background-image: url("../public/images/header_1920_450.jpg");
        background-size: 100% 100%;
        padding-top: 25%;
    }

    //font-size: 125%;

    .menu-text a {
        color: lighten($orange-light, 20%);

        &:hover {
            color: lighten($orange-light, 10%);
        }
    }

    .dropdown li:not(.has-form) a:not(.button) {
        color: lighten($orange-light, 10%);

        &:hover {
            color: lighten($orange-light, 20%);
            background-color: $orange-dark;
        }
    }

    .top-bar {
        background-color: darken($body-background, 5%);
        -webkit-border-bottom-left-radius: 6px;
        -webkit-border-bottom-right-radius: 6px;
        -moz-border-radius-bottomleft: 6px;
        -moz-border-radius-bottomright: 6px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    font-family: 'TF2 Build', "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    -webkit-box-shadow: 0 3px 13px -3px rgba(15,10,7,1);
    -moz-box-shadow: 0 3px 13px -3px rgba(15,10,7,1);
    box-shadow: 0 3px 13px -3px rgba(15,10,7,1);
}

#feet {
    //padding: 1em;
    background-color: darken($orange-dark, 5%);
    background-image: url('../public/images/thinking_about_feet.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    height: 235px;
    margin-top: 1em;

    .cell {
        padding-top: 6em;
    }

    a {
        font-weight: 700;
    }

    li, a {
        color: $orange-light;
    }

    -webkit-border-top-left-radius: 6px;
    -webkit-border-top-right-radius: 6px;
    -moz-border-radius-topleft: 6px;
    -moz-border-radius-topright: 6px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

#servers {
    font-size: 125%;

    .connect_link {
        @include button(true, $connect-background, lighten($connect-background, 5%), $orange-dark);
        font-weight: 900;
        color: lighten($orange-light, 10%) !important;
        margin-bottom: 0;
        font-size: 100%;
        font-family: 'TF2 Build', "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
    }

    .server_info, .player_info {
        -moz-border-radius: 6px;
        border-radius: 6px;
        margin-bottom: 10px;
    }
    .flag img {

        height: 50px;
        width: 50px;
    }
    .server_info {
        background-color: darken($body-background, 5%);

        &:hover {
            background-color: darken($body-background, 10%);
            cursor: pointer;
        }

        h5, h6, p {
            text-align: center;
            padding-top: 15px;
        }

        h5 {
            padding-left: 20px;
            text-align: left;
        }
    }

    .player_info {
        background-color: darken($body-background, 5%);

        p {
            font-size: 90%;
        }
    }
}


.map_link {
    font-weight: 700;
    color: $orange-light;

    &:hover {
        color: lighten($orange-light, 10%);
    }
}

.map_list {
    list-style-image: none;
    margin: 0;
}

.center_img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.home_left {
    background-image: url('../public/images/commish_500.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    height: 806px;
}

p {
    font-size: 125%;
}

.rules {
    p {
        margin-bottom: 2em;
    }
}

$rotation: 3deg;
$borderRadius: 15px;
$borderWidth: 5px;

.home_grid {
    > div {
        margin-bottom: 1em;
    }
    padding-top: 1rem;
    .rotate_right:hover {
        -webkit-transform: rotate($rotation);
        transform: rotate($rotation);

    }
    .rotate_left:hover {
        -webkit-transform: rotate(-$rotation);
        transform: rotate(-$rotation);
    }
    video {
        border-radius: $borderRadius;
        -webkit-backface-visibility: hidden;
        width: 100%;
    }
    .border_discord {
        border: $borderWidth solid rgb(0, 42, 0);
    }
    .border_instagram {
        border: $borderWidth solid rgb(0, 6, 107);
    }
    .border_merch {
        border: $borderWidth solid rgb(50, 8, 61);
    }
    .border_servers {
        border: $borderWidth solid rgb(139, 13, 0);
    }
    .border_credits {
        border: $borderWidth solid rgb(153, 85, 0);
    }
    .border_settings {
         border: $borderWidth solid rgb(103, 89, 44);
    }

}

img.center {
    display: block;
    margin: 0 auto;
}

.credit_block {
    padding-bottom: 5em;
}

.header_h1 {
    margin-top: 0.5em;
}